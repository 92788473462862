var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.recommendList
    ? _c(
        "div",
        { staticClass: "recommendWheels" },
        [
          _c("span", { staticClass: "spinItem" }, [
            _vm._v(_vm._s(_vm.$t("wheelList.Related wheels"))),
          ]),
          _vm._v(" "),
          _vm._l(_vm.recommendList, function (w, index) {
            return _c(
              "div",
              { key: index, staticStyle: { "padding-top": "20px" } },
              [
                _c(
                  "span",
                  { staticClass: "wheelTitle" },
                  [
                    _c("router-link", { attrs: { to: `/${w.path}` } }, [
                      _vm._v(_vm._s(w.name)),
                    ]),
                  ],
                  1
                ),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticStyle: { "font-size": "16px" } }, [
                  _vm._v(_vm._s(w.description)),
                ]),
              ]
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
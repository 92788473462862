var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        active: _vm.optionsDialogVisible,
        width: 640,
        scroll: "keep",
        "full-screen": _vm.$mq == "mobile",
      },
      on: {
        "update:active": function ($event) {
          _vm.optionsDialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "modal-card", staticStyle: { width: "auto" } }, [
        _c(
          "section",
          { staticClass: "modal-card-body can-go-dark" },
          [
            _c(
              "b-tabs",
              {
                attrs: { type: "is-boxed", size: "is-small" },
                model: {
                  value: _vm.activeTab,
                  callback: function ($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab",
                },
              },
              [
                _c(
                  "b-tab-item",
                  { attrs: { label: _vm.$t("optionsdialog.During spin") } },
                  [
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column is-2" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("optionsdialog.Sound")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c(
                            "b-select",
                            {
                              model: {
                                value: _vm.wheelConfig.duringSpinSound,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.wheelConfig,
                                    "duringSpinSound",
                                    $$v
                                  )
                                },
                                expression: "wheelConfig.duringSpinSound",
                              },
                            },
                            _vm._l(_vm.duringSpinSounds, function (sound) {
                              return _c(
                                "option",
                                {
                                  key: sound.value,
                                  domProps: { value: sound.value },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t(sound.name)) +
                                      "\n                "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.wheelConfig.allowDuplicates,
                          callback: function ($$v) {
                            _vm.$set(_vm.wheelConfig, "allowDuplicates", $$v)
                          },
                          expression: "wheelConfig.allowDuplicates",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("optionsdialog.Allow duplicates on wheel")
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.wheelConfig.slowSpin,
                          callback: function ($$v) {
                            _vm.$set(_vm.wheelConfig, "slowSpin", $$v)
                          },
                          expression: "wheelConfig.slowSpin",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("optionsdialog.Spin slowly")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("optionsdialog.Spin time (seconds)")) +
                        "\n          "
                    ),
                    _c(
                      "b-slider",
                      {
                        attrs: { min: 1, max: 60 },
                        model: {
                          value: _vm.wheelConfig.spinTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.wheelConfig, "spinTime", $$v)
                          },
                          expression: "wheelConfig.spinTime",
                        },
                      },
                      [
                        _vm._l([10, 20, 30, 40, 50, 60], function (val) {
                          return [
                            _c(
                              "b-slider-tick",
                              { key: val, attrs: { value: val } },
                              [_vm._v(_vm._s(val))]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.$t("optionsdialog.Max names on wheel"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("optionsdialog.All names in the text-box")
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-slider",
                      {
                        attrs: { min: 4, max: 500 },
                        model: {
                          value: _vm.wheelConfig.maxNames,
                          callback: function ($$v) {
                            _vm.$set(_vm.wheelConfig, "maxNames", $$v)
                          },
                          expression: "wheelConfig.maxNames",
                        },
                      },
                      [
                        _vm._l(
                          [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
                          function (val) {
                            return [
                              _c(
                                "b-slider-tick",
                                { key: val, attrs: { value: val } },
                                [_vm._v(_vm._s(val))]
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab-item",
                  { attrs: { label: _vm.$t("optionsdialog.After spin") } },
                  [
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column is-2" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("optionsdialog.Sound")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c(
                            "b-select",
                            {
                              model: {
                                value: _vm.wheelConfig.afterSpinSound,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.wheelConfig,
                                    "afterSpinSound",
                                    $$v
                                  )
                                },
                                expression: "wheelConfig.afterSpinSound",
                              },
                            },
                            _vm._l(_vm.afterSpinSounds, function (sound) {
                              return _c(
                                "option",
                                {
                                  key: sound.value,
                                  domProps: { value: sound.value },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t(sound.name)) +
                                      "\n                "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.wheelConfig.animateWinner,
                          callback: function ($$v) {
                            _vm.$set(_vm.wheelConfig, "animateWinner", $$v)
                          },
                          expression: "wheelConfig.animateWinner",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("optionsdialog.Animate winning entry")
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.wheelConfig.launchConfetti,
                          callback: function ($$v) {
                            _vm.$set(_vm.wheelConfig, "launchConfetti", $$v)
                          },
                          expression: "wheelConfig.launchConfetti",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("optionsdialog.Launch confetti")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.wheelConfig.autoRemoveWinner,
                          callback: function ($$v) {
                            _vm.$set(_vm.wheelConfig, "autoRemoveWinner", $$v)
                          },
                          expression: "wheelConfig.autoRemoveWinner",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "optionsdialog.Auto-remove winner after 5 seconds"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.wheelConfig.displayWinnerDialog,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.wheelConfig,
                              "displayWinnerDialog",
                              $$v
                            )
                          },
                          expression: "wheelConfig.displayWinnerDialog",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "optionsdialog.Display popup with message:"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("b-input", {
                      staticStyle: { "margin-left": "25px" },
                      attrs: {
                        disabled: !_vm.wheelConfig.displayWinnerDialog,
                        maxlength: "100",
                      },
                      model: {
                        value: _vm.wheelConfig.winnerMessage,
                        callback: function ($$v) {
                          _vm.$set(_vm.wheelConfig, "winnerMessage", $$v)
                        },
                        expression: "wheelConfig.winnerMessage",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.wheelConfig.playClickWhenWinnerRemoved,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.wheelConfig,
                              "playClickWhenWinnerRemoved",
                              $$v
                            )
                          },
                          expression: "wheelConfig.playClickWhenWinnerRemoved",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "optionsdialog.Play click sound when the winner is removed"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab-item",
                  { attrs: { label: _vm.$t("optionsdialog.Colors") } },
                  [
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "columns",
                        staticStyle: { "margin-bottom": "10px" },
                      },
                      [
                        _c("div", { staticClass: "column is-one-third" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("optionsdialog.Apply a theme")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "column" },
                          [
                            _c(
                              "b-field",
                              { attrs: { grouped: "" } },
                              [
                                _c(
                                  "b-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "optionsdialog.Pick category"
                                      ),
                                      expanded: "",
                                    },
                                    model: {
                                      value: _vm.colorThemeCategory,
                                      callback: function ($$v) {
                                        _vm.colorThemeCategory = $$v
                                      },
                                      expression: "colorThemeCategory",
                                    },
                                  },
                                  _vm._l(
                                    Object.keys(_vm.colorThemeCategories),
                                    function (categoryName) {
                                      return _c(
                                        "option",
                                        {
                                          key: categoryName,
                                          domProps: { value: categoryName },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(categoryName) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "optionsdialog.Pick theme"
                                      ),
                                      expanded: "",
                                    },
                                    model: {
                                      value: _vm.colorTheme,
                                      callback: function ($$v) {
                                        _vm.colorTheme = $$v
                                      },
                                      expression: "colorTheme",
                                    },
                                  },
                                  _vm._l(
                                    Object.keys(_vm.colorThemes),
                                    function (themeName) {
                                      return _c(
                                        "option",
                                        {
                                          key: themeName,
                                          domProps: { value: themeName },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(themeName) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "columns",
                        staticStyle: { "margin-bottom": "10px" },
                      },
                      [
                        _c("div", { staticClass: "column is-one-third" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("optionsdialog.Customize colors")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "column" }, [
                          _c(
                            "div",
                            { staticClass: "color-grid" },
                            _vm._l(
                              _vm.wheelConfig.colorSettings,
                              function (setting, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c("b-checkbox", {
                                      model: {
                                        value: setting.enabled,
                                        callback: function ($$v) {
                                          _vm.$set(setting, "enabled", $$v)
                                        },
                                        expression: "setting.enabled",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: setting.color,
                                          expression: "setting.color",
                                        },
                                      ],
                                      attrs: { type: "color" },
                                      domProps: { value: setting.color },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            setting,
                                            "color",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "columns",
                        staticStyle: { "margin-bottom": "10px" },
                      },
                      [
                        _c("div", { staticClass: "column is-one-third" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("optionsdialog.Background color")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "column" }, [
                          _c("div", { staticClass: "color-grid" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.wheelConfig.pageBackgroundColor,
                                  expression: "wheelConfig.pageBackgroundColor",
                                },
                              ],
                              attrs: { type: "color" },
                              domProps: {
                                value: _vm.wheelConfig.pageBackgroundColor,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.wheelConfig,
                                    "pageBackgroundColor",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-tab-item",
                  { attrs: { label: _vm.$t("optionsdialog.Image") } },
                  [
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column is-half" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "optionsdialog.Image in the center of the wheel"
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: { expanded: "" },
                              model: {
                                value: _vm.wheelConfig.pictureType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.wheelConfig, "pictureType", $$v)
                                },
                                expression: "wheelConfig.pictureType",
                              },
                            },
                            [
                              _c("option", { attrs: { value: "none" } }, [
                                _vm._v(_vm._s(_vm.$t("optionsdialog.None"))),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "gallery" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("optionsdialog.From Gallery"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "uploaded" } }, [
                                _vm._v(_vm._s(_vm.$t("optionsdialog.Custom"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.wheelConfig.pictureType == "gallery",
                            expression: "wheelConfig.pictureType=='gallery'",
                          },
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            "optionsdialog.Select a gallery picture"
                          ),
                          expanded: "",
                        },
                        model: {
                          value: _vm.wheelConfig.galleryPicture,
                          callback: function ($$v) {
                            _vm.$set(_vm.wheelConfig, "galleryPicture", $$v)
                          },
                          expression: "wheelConfig.galleryPicture",
                        },
                      },
                      _vm._l(_vm.galleryPictures, function (pic) {
                        return _c(
                          "option",
                          {
                            key: pic.fileName,
                            domProps: { value: pic.fileName },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(pic.title) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("b-input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.wheelConfig.pictureType == "uploaded",
                          expression: "wheelConfig.pictureType=='uploaded'",
                        },
                      ],
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.wheelConfig.customPictureName,
                        callback: function ($$v) {
                          _vm.$set(_vm.wheelConfig, "customPictureName", $$v)
                        },
                        expression: "wheelConfig.customPictureName",
                      },
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "b-field",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.wheelConfig.pictureType == "uploaded",
                            expression: "wheelConfig.pictureType=='uploaded'",
                          },
                        ],
                        staticClass: "file",
                      },
                      [
                        _c("br"),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "b-upload",
                          {
                            attrs: { accept: "image/*" },
                            model: {
                              value: _vm.uploadedImage,
                              callback: function ($$v) {
                                _vm.uploadedImage = $$v
                              },
                              expression: "uploadedImage",
                            },
                          },
                          [
                            _c("a", { staticClass: "button" }, [
                              _c("i", { staticClass: "fas fa-upload" }),
                              _vm._v(
                                "\n                 \n                " +
                                  _vm._s(_vm.$t("optionsdialog.Upload image")) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column is-one-third" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("optionsdialog.Image size")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "column" },
                        [
                          _c(
                            "b-select",
                            {
                              model: {
                                value: _vm.wheelConfig.hubSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.wheelConfig, "hubSize", $$v)
                                },
                                expression: "wheelConfig.hubSize",
                              },
                            },
                            _vm._l(_vm.hubSizes, function (hubSize) {
                              return _c(
                                "option",
                                { key: hubSize, domProps: { value: hubSize } },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(hubSize) +
                                      "\n                "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "footer",
          {
            staticClass: "modal-card-foot",
            staticStyle: { "justify-content": "flex-end" },
          },
          [
            _c(
              "b-button",
              {
                attrs: { size: "is-medium" },
                on: {
                  click: function ($event) {
                    _vm.optionsDialogVisible = false
                  },
                },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("common.Cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: { size: "is-medium", type: "is-primary" },
                on: { click: _vm.saveOptions },
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("common.OK")) + "\n      ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
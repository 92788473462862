var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("ins", {
        staticClass: "adsbygoogle",
        staticStyle: { display: "block" },
        attrs: {
          "data-ad-client": "ca-pub-4238618992091351",
          "data-ad-slot": "7859701734",
          "data-ad-format": "auto",
          "data-full-width-responsive": "true",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div v-if="recommendList" class="recommendWheels">
    <span class="spinItem">{{ $t('wheelList.Related wheels') }}</span>
    <div v-for="(w, index) of recommendList" :key="index" style="padding-top: 20px">
        <span class="wheelTitle">
          <router-link :to="`/${w.path}`">{{ w.name }}</router-link>
        </span><br/>
      <span style="font-size: 16px; ">{{ w.description }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "recommendWheels",
  props:['recommendList'],
  data() {
    return {
      recommendList: this.recommendList
    }
  }
}
</script>

<style scoped>
.recommendWheels {
  flex: 1;
  background: aliceblue;
  border-radius: 10px;
  padding-top: 50px;
}

.spinItem {
  font-size: xx-large;
  padding-top: 1px;
  text-align: left;
  font-weight: bold;
  font-family: Helvetica, serif;
}

.wheelTitle {
  font-size: x-large;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .recommendWheels {
    padding-top: 30px;
  }
}
</style>
<template>
  <span>
    <section class="mySection">
      <div class="columns">
        <div class="column is-6" style="padding-top: 10px;">
            <h2 class="spinItem">{{ this.relatedWheelsCategory }}</h2>
            <div v-for="(w, index) of this.relatedWheels" :key="index">
              <div v-if="w.name != null" style="padding-bottom: 10px;">
                <span class="wheelTitle">
                  <a :href="`${w.path}`" @click="goto(w.path)">{{ w.name }}</a>
                </span><br/>
                <span>
                  {{ w.description }}
                </span>
              </div>
            </div>
        </div>
        <div class="column is-6" style="padding-top: 10px;">
            <h2 class="spinItem">{{ this.popWheelsCategory }}</h2>
            <div v-for="(w, index) of this.popWheels" :key="index">
              <div v-if="w.name != null" style="padding-bottom: 10px;">
                <span class="wheelTitle">
                  <a :href="`${w.path}`" @click="goto(w.path)">{{ w.name }}</a>
                </span><br/>
                <span>
                  {{ w.description }}
                </span>
              </div>
            </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6" style="padding-top: 10px;">
            <h2 class="spinItem">{{ this.newWheelsCategory }}</h2>
            <div v-for="(w, index) of this.newWheels" :key="index">
              <div v-if="w.name != null" style="padding-bottom: 10px;">
                <span class="wheelTitle">
                  <a :href="`${w.path}`" @click="goto(w.path)">{{ w.name }}</a>
                </span><br/>
                <span>
                  {{ w.description }}
                </span>
              </div>
            </div>
        </div>
        <div class="column is-6" style="padding-top: 10px;">
            <h2 class="spinItem">{{ this.otherWheelsCategory }}</h2>
            <div v-for="(w, index) of this.otherWheels" :key="index">
              <div v-if="w.name != null" style="padding-bottom: 10px;">
                <span class="wheelTitle">
                  <a :href="`${w.path}`" @click="goto(w.path)">{{ w.name }}</a>
                </span><br/>
                <span>
                  {{ w.description }}
                </span>
              </div>
            </div>
        </div>
      </div>
    </section>
  </span>
</template>

<script>

import * as Util from "./Util";

export default {
  name: 'wheelList',
  props: ['newWheels', 'popWheels', 'relatedWheels', 'otherWheels'],
  data() {
    return {
      newWheels: this.newWheels,
      newWheelsCategory: 'New wheels',
      popWheels: this.popWheels,
      popWheelsCategory: 'Popular wheels',
      relatedWheels: this.relatedWheels,
      relatedWheelsCategory: 'Related wheels',
      otherWheels: this.otherWheels,
      otherWheelsCategory: 'Other wheels'
    }
  },
  methods: {
    goto(path) {
      Util.gotoInGoogleAnalytics('HomeWheelsPageGoto', path, '')
    }
  }
}
</script>

<style scoped>
.spinItem {
  padding: 10px 0;
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
  font-family: Helvetica, serif;
}

.wheelTitle {
  font-size: x-large;
  font-weight: bold;
  padding-top: 10px;
}
.mySection {
  padding-left: 40px;
  padding-right: 40px;
}

@media screen and (max-width: 480px) {
  .mySection {
    padding: 0;
  }
}
</style>
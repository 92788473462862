import { render, staticRenderFns } from "./textboxbuttons.vue?vue&type=template&id=370a9134&scoped=true&"
import script from "./textboxbuttons.vue?vue&type=script&lang=js&"
export * from "./textboxbuttons.vue?vue&type=script&lang=js&"
import style0 from "./textboxbuttons.vue?vue&type=style&index=0&id=370a9134&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "370a9134",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/lorryhou/Dev/IdeaProjects/wheel-spinner/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('370a9134')) {
      api.createRecord('370a9134', component.options)
    } else {
      api.reload('370a9134', component.options)
    }
    module.hot.accept("./textboxbuttons.vue?vue&type=template&id=370a9134&scoped=true&", function () {
      api.rerender('370a9134', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "static/textboxbuttons.vue"
export default component.exports
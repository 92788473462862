<!--
Copyright 2020 Google LLC

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
-->
<template>
  <span>
    <loading-screen v-show="loading"></loading-screen>
    <toolbar
      v-on:show-snackbar-message="showSnackbarMessage"
      v-on:reset-wheel="resetWheel()"
      v-on:open-customize-dialog="openCustomizeDialog()"
      v-on:set-locale="setLocale"
    ></toolbar>
    <section class="section">
      <div class="columns" v-bind:class="{ 'is-centered': $store.state.appStatus.fullScreen }">
        <div class="column is-6" style="padding-top: 20px;"
             v-bind:class="{ 'is-6': $store.state.appStatus.fullScreen }">
          <spinningwheel ref="spinningwheel"
                         v-on:wheel-started="wheelStarted"
                         v-on:name-changed="nameChanged"
                         v-on:wheel-stopped="wheelStopped"
          ></spinningwheel>
          <div class="spinLabel"><a href="/hotwheels" @click="gotoWheels('home')">{{ $t('spinningwheel.Explore more wheels') }}</a></div>
        </div>
        <div class="column is-3" v-show="!$store.state.appStatus.fullScreen">
          <span style="font-family:Helvetica,serif">
            {{ $t('app.Enter names here') }}
          </span>
          <br/>
          <textboxbuttons></textboxbuttons>
          <textbox></textbox>
          <entry-counter></entry-counter>
          <!--          <app-info v-on:open-options-dialog="openOptionsDialog()"></app-info>-->
        </div>
        <div class="column is-3" v-show="!$store.state.appStatus.fullScreen">
          <app-show :is-wheel-page="isWheelPage"></app-show>
        </div>
      </div>
      <div class="wheelContent">
        <div v-html="this.content"></div>
      </div>
      <wheel-list v-if="showWheelList" :new-wheels="newWheels" :pop-wheels="popularWheels"
                  :other-wheels="otherWheels" :related-wheels="relatedWheels"/>
      <faq v-show="!isWheelPage" :title="this.$t('faq.title')" :questions="this.$t('faq.questions')"></faq>
      <bottom></bottom>
    </section>

    <optionsdialog ref="optionsdialog" v-on:show-snackbar-message="showSnackbarMessage"></optionsdialog>
    <winnerdialog ref="winnerdialog" v-on:remove-name="removeName" v-on:remove-name-all="removeNameAll"></winnerdialog>

    <winneranimation ref="winneranimation"></winneranimation>

    <cookie-law theme="base--rounded" buttonLink="privacy.html" buttonLinkText="Privacy Policy">
      <div slot-scope="props">
        <span v-html="privacyHtml"></span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <button class="skew" @click="props.accept"><span>{{ privacyAccept }}</span></button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <button class="skew" @click="props.close"><span>{{ privacyIgnore }}</span></button>
      </div>
    </cookie-law>
  </span>
</template>

<script>
import loadingScreen from './loadingScreen.vue';
import toolbar from './toolbar.vue';
import spinningwheel from './spinningwheel.vue';
import textboxbuttons from './textboxbuttons.vue';
import textbox from './textbox.vue';
import appInfo from './appInfo.vue';
import optionsdialog from './optionsdialog.vue';
import winnerdialog from './winnerdialog.vue';
import winneranimation from './winneranimation.vue';
import entryCounter from './entry-counter.vue';
import appShow from './appShow.vue';
import bottom from "./bottom.vue";
import recommendWheels from "./recommendWheels.vue";
import faq from "./faq.vue";
import wheelList from "./wheelList.vue";
import cookieLaw from 'vue-cookie-law';

import * as ConfettiLauncher from './ConfettiLauncher.js';
import * as Util from './Util.js';
import * as FullScreen from './FullScreen.js';
import WheelConfig from './WheelConfig.js';
import Preferences from './Preferences.js';
import PageReloader from './PageReloader.js';
import * as Audio from './audio.js';
import * as Locales from './Locales.js';

export default {
  components: {
    bottom, loadingScreen, toolbar, textboxbuttons, textbox, spinningwheel, appInfo, winnerdialog, optionsdialog,
    winneranimation, entryCounter, appShow, recommendWheels, faq, wheelList, cookieLaw
  },
  created() {
    // this.initNavbar()
  },
  mounted() {
    this.initNavbar()
  },
  data() {
    return {
      waitAnimation: {},
      loading: true,
      setting: {
        title: this.$t('home.seo title'),
        keywords: this.$t('home.seo keywords'),
        description: this.$t('home.seo description')
      },
      originContent: this.$t('home.description'),
      privacyHtml: this.$t('home.privacy'),
      privacyAccept: this.$t('home.privacy accept'),
      privacyIgnore: this.$t('home.privacy ignore'),
      content: '',
      isWheelPage: false,
      showWheelList: false,
      relatedWheels: [],
      newWheels: [],
      popularWheels: [],
      otherWheels: []
    };
  },
  metaInfo() {
    return {
      title: this.setting.title,
      meta: [
        {name: "keywords", content: this.setting.keywords},
        {name: "description", content: this.setting.description},
      ]
    };
  },
  computed: {
    names() {
      return this.$store.state.wheelConfig.names
    },
    wheelConfig() {
      return this.$store.state.wheelConfig
    },
    preferences() {
      return this.$store.state.preferences
    },
    fullScreen() {
      return this.$store.state.appStatus.fullScreen
    },
    wheelSpinning() {
      return this.$store.state.appStatus.wheelSpinning
    },
    darkMode() {
      return this.$store.getters.darkMode
    },
    pageColor() {
      return this.$store.state.wheelConfig.pageBackgroundColor
    }
  },
  watch: {
    '$route'(to, from) {
      // 对路由变化作出响应...
      // console.log('to.path----',to.path)//跳转后路由
      // console.log('from----',from)//跳转前路由
      this.navSelect(to.path)
    },
    wheelConfig(newValue, oldValue) {
      // Util.updateColorStyles(this.darkMode, '#777', this.pageColor);
      localStorage.setItem('LastWheelConfig', this.$store.state.wheelConfig.getJson());
      Audio.preloadSounds(newValue.duringSpinSound, newValue.afterSpinSound);
    },
    names(newValue, oldValue) {
      localStorage.setItem('LastWheelConfig', this.$store.state.wheelConfig.getJson());
    },
    preferences(newValue) {
      // Util.updateColorStyles(this.darkMode, '#777', this.pageColor);
      localStorage.setItem('Preferences', newValue.getJson());
    },
    fullScreen(newValue, oldValue) {
      if (newValue) {
        Util.trackEvent('Wheel', 'EnterFullscreen', '');
        FullScreen.turnOnFullscreen();
      }
      if (!newValue) {
        Util.trackEvent('Wheel', 'ExitFullscreen', '');
        FullScreen.turnOffFullscreen();
      }
    },
  },
  methods: {
    gotoWheels(category) {
      Util.gotoInGoogleAnalytics(category, 'toolbar', '')
    },
    initNavbar() {
      this.content = this.originContent;
      const path = this.$route.path;
      this.navSelect(path)
    },
    // 根据语言获取对应首页分类数据
    async getWheelList(lang) {
      const fetchNames = (category, lang) => fetch(`https://api.spinthewheel.cc/spin/category-wheels?category=${category}&lang=${lang}`);
      let response = await fetchNames('Home', lang);

      const data = await response.json()
      if (data.code === 200 && data.data != null) {
        let count = 0;
        const obj = eval(data.data);
        this.newWheels = obj.New;
        if (this.newWheels != null && this.newWheels.length > 0) {
          count += this.newWheels.length;
          this.newWheelsCategory = this.newWheels[0].subcategory;
        }
        this.popularWheels = obj.Popular;
        if (this.popularWheels != null && this.popularWheels.length > 0) {
          count += this.popularWheels.length;
          this.popWheelsCategory = this.popularWheels[0].subcategory;
        }
        this.relatedWheels = obj.Related;
        if (this.relatedWheels != null && this.relatedWheels.length > 0) {
          count += this.relatedWheels.length;
          this.relatedWheelsCategory = this.relatedWheels[0].subcategory;
        }
        this.otherWheels = obj.Other;
        if (this.otherWheels != null && this.otherWheels.length > 0) {
          count += this.otherWheels.length;
          this.otherWheelsCategory = this.otherWheels[0].subcategory;
        }

        if (count > 0) {
          this.showWheelList = true;
        }
      }
    },
    // 刷新转盘数据
    async navSelect(path) {
      // 获取参数
      const locales = Locales.getNames();
      let lang = this.$route.params.lang === undefined ? 'en' : this.$route.params.lang;
      const {id: wheelId} = Util.getUrlParams(window.location.search);
      let wheelName = this.$route.params.wheelName
      let localeIndex = locales.indexOf(wheelName);
      if (localeIndex > -1) {
        /**
         *  如果是这种格式就替换：
         *  localhost/tw ==> lang=tw, wheelName=undefined
         */
        lang = wheelName;
        wheelName = undefined;
      }

      const wheelConfig = new WheelConfig(this.$t('common.We have a winner!'), this.$t('home.title'), this.$t('home.names'), lang);
      let response;

      // 英文首页
      if ((wheelId === undefined && (path === '/'))) {
        let lastWheelConfig = localStorage.getItem('LastWheelConfig');
        if (lastWheelConfig === null) {
          console.log('load default wheel.');
        } else {
          let lastWheelJson = JSON.parse(lastWheelConfig);

          if (lastWheelJson.lang === lang) {
            console.log('load last wheel.');
            wheelConfig.loadJson(localStorage.getItem('LastWheelConfig'));
          }
        }
        await this.getWheelList(lang);
      } else if (wheelId === undefined && wheelName === undefined) {
        // 非英文多语言首页
        console.log('load default wheel.');
        await this.getWheelList(lang);
      } else {
        // 从接口获取转盘数据
        if (!Util.myIsNaN(wheelId)) {
          // app通过id获取
          const fetchNames = (wheelId) => fetch(`https://api.spinthewheel.cc/spin/get-wheel-new?id=${wheelId}`);
          response = await fetchNames(wheelId);
        } else {
          // 网站通过路径获取
          const fetchNames = (wheelName, lang) => fetch(`https://api.spinthewheel.cc/spin/get-wheel?p=${wheelName}&lang=${lang}`);
          response = await fetchNames(wheelName, lang);
        }

        const data = await response.json()
        if (data.code === 200 && data.data.name !== undefined) {
          // 转盘页，不展示 appShow中的desc 和 faq 内容
          this.isWheelPage = true

          wheelConfig.title = data.data.name
          wheelConfig.names = data.data.names;
          wheelConfig.colorSettings = data.data.colorSettings;

          if (data.data.title !== undefined) {
            this.setting.title = data.data.title;
            this.setting.keywords = data.data.keywords !== undefined ? data.data.keywords : this.setting.keywords;
            this.setting.description = data.data.description !== undefined ? data.data.description.substring(0, 150) : this.setting.description;
            this.content = data.data.content !== undefined ? data.data.content : this.originContent;
            this.relatedWheels = data.data.recommendWheels;
            this.newWheels = data.data.newWheels;
            this.popularWheels = data.data.popularWheels;
            this.otherWheels = data.data.otherWheels;

            let count = 0;
            if (this.relatedWheels != null && this.relatedWheels.length > 0) {
              count += this.relatedWheels.length;
            }
            if (this.newWheels != null && this.newWheels.length > 0) {
              count += this.newWheels.length;
            }
            if (this.popularWheels != null && this.popularWheels.length > 0) {
              count += this.popularWheels.length;
            }
            if (this.otherWheels != null && this.otherWheels.length > 0) {
              count += this.otherWheels.length;
            }

            if (count > 0) {
              this.showWheelList = true;
            }
          }
        } else {
          this.showSnackbarMessage(this.$t('app.Sorry, the wheel not exist! Loaded default names and options!'));
          wheelConfig.loadJson(localStorage.getItem('LastWheelConfig'));
        }
      }

      this.$store.commit('setWheelConfig', wheelConfig);
      this.setDocLangProperties();
      this.loadPreferences();
      this.startFullscreenDetection();
      this.startOnlineDetection();
      this.startVisibilityDetection();
      this.displayLanguageTip();
      this.refreshWheelOnFontLoad();
      this.loading = false;
    },
    startFullscreenDetection() {
      const self = this;
      document.addEventListener('fullscreenchange', event => {
        if (FullScreen.fullscreenOn()) {
          self.$store.commit('enterFullScreen');
        } else {
          self.$store.commit('exitFullScreen');
        }
      })
    },
    startOnlineDetection() {
      this.$store.commit('setOnline', navigator.onLine);
      const self = this;
      window.addEventListener('online', event => {
        self.$store.commit('setOnline', navigator.onLine);
      });
      window.addEventListener('offline', event => {
        self.$store.commit('setOnline', navigator.onLine);
      });
    },
    startVisibilityDetection() {
      const reloader = new PageReloader();
      document.addEventListener("visibilitychange", function () {
        reloader.reloadOutdatedPage(document.hidden);
      })
    },
    displayLanguageTip() {
      // console.log('this.$i18n.locale', this.$i18n.locale)
      // console.log('navigator.languages', navigator.languages)
      const tipLocale = Locales.getLangTipLocale(this.$i18n.locale, navigator.languages);
      if (tipLocale) {
        setTimeout(async () => {
          const file = Locales.getMessagesFileName(tipLocale);
          const messages = (
            await import(/* webpackChunkName: "locale-[request]" */
              `./locales/${file}`)
          ).default;
          const msg = messages['app']['Click the Language menu'];
          this.showSnackbarMessage(msg);
        }, 3000);
      }
    },
    loadPreferences() {
      const prefs = new Preferences();
      prefs.loadJson(localStorage.getItem('Preferences'));
      this.$store.commit('setPreferences', prefs);
    },
    setDocLangProperties() {
      document.documentElement.setAttribute('lang', this.$i18n.locale);
      // document.title = 'Wheel-spinner | ' + this.$t('app.Random name picker');
      const desc = this.$t('app.Free and easy to use');
      // document.querySelector('meta[name="description"]').setAttribute("content", desc);
    },
    refreshWheelOnFontLoad() {
      if (document.fonts) {
        const self = this;
        document.fonts.ready.then(function () {
          self.$refs.spinningwheel.refresh();
        })
      }
    },
    resetWheel() {
      this.title = this.setting.title;
      this.content = this.originContent;
      this.isWheelPage = false;
      // 设置语言
      let lang = this.$route.params.lang === undefined ? 'en' : this.$route.params.lang;
      const wheelConfig = new WheelConfig(this.$t('common.We have a winner!'), this.$t('home.title'), this.$t('home.names'), lang);
      this.$store.commit('setWheelConfig', wheelConfig);
      this.showSnackbarMessage(this.$t('app.Loaded default names and options'));
    },
    openCustomizeDialog() {
      Util.trackEvent('Wheel', 'ShowCustomizeDialog', '');
      this.$refs.optionsdialog.show();
    },
    setLocale(locale) {
      console.log('locale', locale)
      window.location.replace(Locales.getRelativeUrl(window.location.hostname, locale));
    },
    nameChanged() {
      const state = this.$store.state;
      if (state.appStatus.wheelSpinning && state.wheelConfig.shouldPlayTicks()) {
        Audio.playTick();
      }
    },
    wheelStarted() {
      Audio.startMusic(this.wheelConfig.duringSpinSound);
    },
    wheelStopped(winningEntry) {
      Audio.stopMusic();
      if (this.wheelConfig.animateWinner) {
        this.$refs.winneranimation.show(winningEntry);
      }
      if (this.wheelConfig.launchConfetti) {
        ConfettiLauncher.launch(this.wheelConfig.getCoalescedColors());
      }
      if (this.wheelConfig.displayWinnerDialog) {
        this.$refs.winnerdialog.show(winningEntry);
      }
      if (this.wheelConfig.autoRemoveWinner) {
        setTimeout(_ => this.removeName(winningEntry), 5000);
      }
      Audio.playAfterSpin(this.wheelConfig.afterSpinSound, Util.extractDisplayText(winningEntry));
    },
    showSnackbarMessage(msg) {
      this.$buefy.toast.open({message: msg, duration: 6000});
    },
    startWaitAnimation() {
      this.waitAnimation = this.$buefy.loading.open({container: null});
    },
    stopWaitAnimation() {
      this.waitAnimation.close();
    },
    removeName(name) {
      if (this.$store.state.wheelConfig.playClickWhenWinnerRemoved) {
        Audio.playClick();
      }
      this.$store.commit('removeName', name);
      const msg = this.$t('app.Removed', {name: Util.extractDisplayText(name, true)});
      this.showSnackbarMessage(msg);
    },
    removeNameAll(name) {
      if (this.$store.state.wheelConfig.playClickWhenWinnerRemoved) {
        Audio.playClick();
      }
      this.$store.commit('removeNameAll', name);
      const msg = this.$t('app.Removed', {name: Util.extractDisplayText(name, true)});
      this.showSnackbarMessage(msg);
    },
    resetWheelRotation() {
      this.$refs.spinningwheel.resetRotation();
    }
  }
}
</script>

<style scoped>
.can-go-dark {
}

.wheelContent {
  flex: 1;
  background: aliceblue;
  padding: 40px;
  border-radius: 10px;
  margin: 50px;
}

.spinLabel {
  font-size: 26px;
  font-weight: bold;
  font-family: Helvetica, serif;
  color: #0059ff;
  transition: 0.5s;
  text-align: center;
}

.spinLabel:hover {
  color: #eec00d;
}

@media screen and (max-width: 480px) {
  .wheelContent {
    padding: 15px;
    margin: 20px 1px;
  }

  p {
    line-height: 150%;
  }

  div {
    line-height: 150%;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { active: _vm.winnerDialogVisible, width: 640, scroll: "keep" },
      on: {
        "update:active": function ($event) {
          _vm.winnerDialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "modal-card", staticStyle: { width: "auto" } }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c(
            "div",
            { staticClass: "modal-card-title", staticStyle: { width: "100%" } },
            [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.winnerMessage) + "\n        "
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "modal-card-body can-go-dark" }, [
          _c("h1", { staticClass: "title" }, [
            _vm.winnerImage
              ? _c("img", {
                  staticStyle: { height: "200px", "vertical-align": "middle" },
                  attrs: { src: _vm.winnerImage },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.winnerText))]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "footer",
          {
            staticClass: "modal-card-foot",
            staticStyle: { "justify-content": "flex-end" },
          },
          [
            _c(
              "b-button",
              {
                attrs: { size: "is-medium" },
                on: {
                  click: function ($event) {
                    _vm.winnerDialogVisible = false
                  },
                },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("common.Close")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showRemoveButton,
                    expression: "showRemoveButton",
                  },
                ],
                ref: "removeButton",
                attrs: { size: "is-medium", type: "is-info" },
                on: { click: _vm.removeWinner },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("winnerdialog.Remove")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showRemoveAllButton,
                    expression: "showRemoveAllButton",
                  },
                ],
                attrs: { size: "is-medium", type: "is-primary" },
                on: { click: _vm.removeWinnerAll },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("winnerdialog.Remove all instances")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
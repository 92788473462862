var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "textarea can-go-dark",
    staticStyle: {
      overflow: "auto",
      "font-family":
        "BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    },
    style:
      "height:" +
      (_vm.$store.state.preferences.appInfoVisible ? "520px" : "520px"),
    attrs: { id: "names", spellcheck: "false", contentEditable: "true" },
    on: {
      paste: _vm.onPaste,
      input: function ($event) {
        return _vm.setNames()
      },
      keyup: _vm.IE_setNames,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <span>
    <div class="faq">
      <div class="spinItem">{{ this.title }}</div>
      <ul>
        <li v-for="item in this.questions">
          <h2 class="line-padding">{{ item.title }}</h2>
          <span v-html="item.content"></span>
        </li>
      </ul>
    </div>
  </span>
</template>

<script>
export default {
  name: "faq",
  props: ['title', 'questions']
}
</script>

<!--todo 适配移动端宽度-->
<style scoped>
.faq {
  padding: 20px 40px;
}
.line-padding {
  padding-top: 20px;
}
.spinItem {
  padding: 10px 0;
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
  font-family: Helvetica, serif;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .faq {
    padding: 15px;
  }
}
</style>
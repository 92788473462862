var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar",
    { attrs: { type: "is-info" } },
    [
      _c(
        "template",
        { slot: "brand" },
        [
          _c(
            "b-navbar-item",
            { staticStyle: { "font-size": "24px" }, attrs: { href: "/" } },
            [
              _c("i", { staticClass: "fa fa-arrow-left" }),
              _vm._v(" " + _vm._s(_vm.toolbarBrand) + "\n    "),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", staticStyle: { "text-align": "center" } },
    [
      _c("h1", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("canvas", {
        style: _vm.spinWidth,
        attrs: { id: "wheelCanvas", width: "700", height: "700" },
        on: {
          click: function ($event) {
            return _vm.spin()
          },
        },
      }),
      _vm._v(" "),
      !_vm.isTouchScreen
        ? _c(
            "div",
            {
              ref: "instructionsLayer",
              attrs: { id: "instructionsLayer" },
              on: {
                click: function ($event) {
                  return _vm.spin()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "instructionsText",
                  attrs: { id: "topInstruction" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("spinningwheel.Click to spin")) +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "instructionsText",
                  staticStyle: { "padding-top": "55%" },
                  attrs: { id: "bottomInstruction" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("spinningwheel.or press ctrl enter")) +
                      "\n    "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isTouchScreen
        ? _c(
            "div",
            {
              ref: "instructionsLayer",
              attrs: { id: "instructionsLayer" },
              on: {
                click: function ($event) {
                  return _vm.spin()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "instructionsText",
                  staticStyle: { "padding-top": "35%" },
                  attrs: { id: "topInstruction" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("spinningwheel.Tap to spin")) +
                      "\n    "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
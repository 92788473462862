import Vue from 'vue';
import Router from 'vue-router';
import App from './app.vue';
import HotWheels from './hotwheels.vue';
import Top10Wheels from './top10wheels.vue';
import AppInfo from "./appInfo.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/hotwheels',
      component: HotWheels
    },
    {
      path: '/top10wheels',
      component: Top10Wheels
    },
    {
      path: '/appinfo',
      component: AppInfo
    },
    {
      path: '/:lang/:wheelName',
      component: App
    },
    {
      path: '/:wheelName',
      component: App
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  },
  mode: 'history'
})

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c("simple-toolbar"),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "section",
          staticStyle: { "padding-top": "0", "padding-bottom": "0" },
        },
        [
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              {
                staticClass: "column is-6",
                staticStyle: { "padding-top": "10px" },
              },
              [
                _c("h2", { staticClass: "spinItem" }, [
                  _vm._v(_vm._s(this.newWheelsCategory)),
                ]),
                _vm._v(" "),
                _vm._l(this.newWheels, function (w, index) {
                  return _c("div", { key: index }, [
                    w.name != null
                      ? _c(
                          "div",
                          { staticStyle: { "padding-bottom": "10px" } },
                          [
                            _c("span", { staticClass: "wheelTitle" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: `/${w.path}` },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goto(w.path)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(w.name))]
                              ),
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(w.description) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "column is-6",
                staticStyle: { "padding-top": "10px" },
              },
              [
                _c("h2", { staticClass: "spinItem" }, [
                  _vm._v(_vm._s(this.trendingWheelsCategory)),
                ]),
                _vm._v(" "),
                _vm._l(this.trendingWheels, function (w, index) {
                  return _c("div", { key: index }, [
                    w.name != null
                      ? _c(
                          "div",
                          { staticStyle: { "padding-bottom": "10px" } },
                          [
                            _c("span", { staticClass: "wheelTitle" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: `/${w.path}` },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goto(w.path)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(w.name))]
                              ),
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(w.description) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              {
                staticClass: "column is-6",
                staticStyle: { "padding-top": "10px" },
              },
              [
                _c("h2", { staticClass: "spinItem" }, [
                  _vm._v(_vm._s(this.relatedWheelsCategory)),
                ]),
                _vm._v(" "),
                _vm._l(this.relatedWheels, function (w, index) {
                  return _c("div", { key: index }, [
                    w.name != null
                      ? _c(
                          "div",
                          { staticStyle: { "padding-bottom": "10px" } },
                          [
                            _c("span", { staticClass: "wheelTitle" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: `/${w.path}` },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goto(w.path)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(w.name))]
                              ),
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(w.description) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "column is-6",
                staticStyle: { "padding-top": "10px" },
              },
              [
                _c("h2", { staticClass: "spinItem" }, [
                  _vm._v(_vm._s(this.otherWheelsCategory)),
                ]),
                _vm._v(" "),
                _vm._l(this.otherWheels, function (w, index) {
                  return _c("div", { key: index }, [
                    w.name != null
                      ? _c(
                          "div",
                          { staticStyle: { "padding-bottom": "10px" } },
                          [
                            _c("span", { staticClass: "wheelTitle" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: `/${w.path}` },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goto(w.path)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(w.name))]
                              ),
                            ]),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(w.description) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("bottom"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <span>
    <simple-toolbar/>
    <section class="section" style="padding-top:0; padding-bottom:0">
      <div class="columns">
        <div class="column is-6" style="padding-top: 10px;">
            <h2 class="spinItem">{{ this.newWheelsCategory }}</h2>
            <div v-for="(w, index) of this.newWheels" :key="index">
              <div v-if="w.name != null" style="padding-bottom: 10px;">
                <span class="wheelTitle">
                  <a :href="`/${w.path}`" @click="goto(w.path)">{{ w.name }}</a>
                </span><br/>
                <span>
                  {{ w.description }}
                </span>
              </div>
            </div>
        </div>
        <div class="column is-6" style="padding-top: 10px;">
            <h2 class="spinItem">{{ this.trendingWheelsCategory }}</h2>
            <div v-for="(w, index) of this.trendingWheels" :key="index">
              <div v-if="w.name != null" style="padding-bottom: 10px;">
                <span class="wheelTitle">
                  <a :href="`/${w.path}`" @click="goto(w.path)">{{ w.name }}</a>
                </span><br/>
                <span>
                  {{ w.description }}
                </span>
              </div>
            </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6" style="padding-top: 10px;">
            <h2 class="spinItem">{{ this.relatedWheelsCategory }}</h2>
            <div v-for="(w, index) of this.relatedWheels" :key="index">
              <div v-if="w.name != null" style="padding-bottom: 10px;">
                <span class="wheelTitle">
                  <a :href="`/${w.path}`" @click="goto(w.path)">{{ w.name }}</a>
                </span><br/>
                <span>
                  {{ w.description }}
                </span>
              </div>
            </div>
        </div>
        <div class="column is-6" style="padding-top: 10px;">
            <h2 class="spinItem">{{ this.otherWheelsCategory }}</h2>
            <div v-for="(w, index) of this.otherWheels" :key="index">
              <div v-if="w.name != null" style="padding-bottom: 10px;">
                <span class="wheelTitle">
                  <a :href="`/${w.path}`" @click="goto(w.path)">{{ w.name }}</a>
                </span><br/>
                <span>
                  {{ w.description }}
                </span>
              </div>
            </div>
        </div>
      </div>
      <bottom></bottom>
    </section>
  </span>
</template>

<script>
import SimpleToolbar from "./simpletoolbar.vue";
import bottom from "./bottom.vue";
import toolbar from "./toolbar.vue";
import * as Util from "./Util";

export default {
  components: {SimpleToolbar, bottom, toolbar},
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      const fetchNames = (category) => fetch(`https://api.spinthewheel.cc/spin/category-wheels?category=${category}`);
      let response = await fetchNames('Top10Wheels');

      const data = await response.json()
      if (data.code === 200 && data.data != null) {
        const obj = eval(data.data);
        this.newWheels = obj.New;
        if (this.newWheels.length > 0) {
          this.newWheelsCategory = this.newWheels[0].subcategory;
        }
        this.trendingWheels = obj.Popular;
        if (this.trendingWheels.length > 0) {
          this.trendingWheelsCategory = this.trendingWheels[0].subcategory;
        }
        this.relatedWheels = obj.Related;
        if (this.relatedWheels.length > 0) {
          this.relatedWheelsCategory = this.relatedWheels[0].subcategory;
        }
        this.otherWheels = obj.Other;
        if (this.otherWheels.length > 0) {
          this.otherWheelsCategory = this.otherWheels[0].subcategory;
        }
      }
    },
    goto(path) {
      Util.gotoInGoogleAnalytics('Top10WheelsPageGoto', path, '')
    }
  },
  data() {
    return {
      newWheels: [],
      newWheelsCategory: '',
      trendingWheels: [],
      trendingWheelsCategory: '',
      relatedWheels: [],
      relatedWheelsCategory: '',
      otherWheels: [],
      otherWheelsCategory: '',
    }
  }
}
</script>

<style scoped>
.spinItem {
  padding: 10px 0;
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
  font-family: Helvetica, serif;
}

.wheelTitle {
  font-size: x-large;
  font-weight: bold;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c("div", { staticClass: "faq" }, [
      _c("div", { staticClass: "spinItem" }, [_vm._v(_vm._s(this.title))]),
      _vm._v(" "),
      _c(
        "ul",
        _vm._l(this.questions, function (item) {
          return _c("li", [
            _c("h2", { staticClass: "line-padding" }, [
              _vm._v(_vm._s(item.title)),
            ]),
            _vm._v(" "),
            _c("span", { domProps: { innerHTML: _vm._s(item.content) } }),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c("loading-screen", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      }),
      _vm._v(" "),
      _c("toolbar", {
        on: {
          "show-snackbar-message": _vm.showSnackbarMessage,
          "reset-wheel": function ($event) {
            return _vm.resetWheel()
          },
          "open-customize-dialog": function ($event) {
            return _vm.openCustomizeDialog()
          },
          "set-locale": _vm.setLocale,
        },
      }),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "section" },
        [
          _c(
            "div",
            {
              staticClass: "columns",
              class: { "is-centered": _vm.$store.state.appStatus.fullScreen },
            },
            [
              _c(
                "div",
                {
                  staticClass: "column is-6",
                  class: { "is-6": _vm.$store.state.appStatus.fullScreen },
                  staticStyle: { "padding-top": "20px" },
                },
                [
                  _c("spinningwheel", {
                    ref: "spinningwheel",
                    on: {
                      "wheel-started": _vm.wheelStarted,
                      "name-changed": _vm.nameChanged,
                      "wheel-stopped": _vm.wheelStopped,
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "spinLabel" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "/hotwheels" },
                        on: {
                          click: function ($event) {
                            return _vm.gotoWheels("home")
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("spinningwheel.Explore more wheels"))
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$store.state.appStatus.fullScreen,
                      expression: "!$store.state.appStatus.fullScreen",
                    },
                  ],
                  staticClass: "column is-3",
                },
                [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Helvetica,serif" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("app.Enter names here")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("textboxbuttons"),
                  _vm._v(" "),
                  _c("textbox"),
                  _vm._v(" "),
                  _c("entry-counter"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$store.state.appStatus.fullScreen,
                      expression: "!$store.state.appStatus.fullScreen",
                    },
                  ],
                  staticClass: "column is-3",
                },
                [
                  _c("app-show", {
                    attrs: { "is-wheel-page": _vm.isWheelPage },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "wheelContent" }, [
            _c("div", { domProps: { innerHTML: _vm._s(this.content) } }),
          ]),
          _vm._v(" "),
          _vm.showWheelList
            ? _c("wheel-list", {
                attrs: {
                  "new-wheels": _vm.newWheels,
                  "pop-wheels": _vm.popularWheels,
                  "other-wheels": _vm.otherWheels,
                  "related-wheels": _vm.relatedWheels,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("faq", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isWheelPage,
                expression: "!isWheelPage",
              },
            ],
            attrs: {
              title: this.$t("faq.title"),
              questions: this.$t("faq.questions"),
            },
          }),
          _vm._v(" "),
          _c("bottom"),
        ],
        1
      ),
      _vm._v(" "),
      _c("optionsdialog", {
        ref: "optionsdialog",
        on: { "show-snackbar-message": _vm.showSnackbarMessage },
      }),
      _vm._v(" "),
      _c("winnerdialog", {
        ref: "winnerdialog",
        on: {
          "remove-name": _vm.removeName,
          "remove-name-all": _vm.removeNameAll,
        },
      }),
      _vm._v(" "),
      _c("winneranimation", { ref: "winneranimation" }),
      _vm._v(" "),
      _c("cookie-law", {
        attrs: {
          theme: "base--rounded",
          buttonLink: "privacy.html",
          buttonLinkText: "Privacy Policy",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return _c("div", {}, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.privacyHtml) },
                }),
                _vm._v("\n          \n      "),
                _c(
                  "button",
                  { staticClass: "skew", on: { click: props.accept } },
                  [_c("span", [_vm._v(_vm._s(_vm.privacyAccept))])]
                ),
                _vm._v("\n          \n      "),
                _c(
                  "button",
                  { staticClass: "skew", on: { click: props.close } },
                  [_c("span", [_vm._v(_vm._s(_vm.privacyIgnore))])]
                ),
              ])
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "b-button",
        {
          attrs: {
            size: "is-small mb-5",
            type: "is-light",
            disabled: _vm.buttonsDisabled,
          },
          on: { click: _vm.shuffle },
        },
        [
          _c("i", { staticClass: "fas fa-random" }),
          _vm._v(" " + _vm._s(_vm.$t("textboxbuttons.Shuffle")) + "\n  "),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          attrs: {
            size: "is-small mb-5",
            type: "is-light",
            disabled: _vm.buttonsDisabled,
          },
          on: { click: _vm.sort },
        },
        [
          _c("i", { staticClass: "fas fa-sort-alpha-up" }),
          _vm._v(" " + _vm._s(_vm.$t("textboxbuttons.Sort")) + "\n  "),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-upload",
        {
          staticClass: "button is-small is-light mb-5",
          attrs: {
            accept: "image/*",
            multiple: "",
            disabled: _vm.buttonsDisabled,
          },
          model: {
            value: _vm.uploadedImage,
            callback: function ($$v) {
              _vm.uploadedImage = $$v
            },
            expression: "uploadedImage",
          },
        },
        [
          _c("i", { staticClass: "far fa-image" }),
          _vm._v(" " + _vm._s(_vm.$t("textboxbuttons.Add image")) + "\n  "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
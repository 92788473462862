var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c("section", [
      _c(
        "div",
        {
          staticClass: "columns",
          staticStyle: {
            "padding-left": "15%",
            "padding-top": "60px",
            "padding-bottom": "0",
            "text-align": "center",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "column is-3",
              staticStyle: { "padding-top": "10px" },
            },
            [_vm._v("@ Copyright 2021-2024")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "column is-2",
              staticStyle: { "padding-top": "10px" },
            },
            [
              _c("a", { attrs: { href: "mailto:tikfollowers@gmail.com" } }, [
                _c("b", [_vm._v(_vm._s(_vm.$t("bottom.Contact US")))]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "column is-2",
              staticStyle: { "padding-top": "10px" },
            },
            [
              _c("a", { attrs: { href: "/privacy.html" } }, [
                _c("b", [_vm._v(_vm._s(_vm.$t("bottom.Privacy policy")))]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "column is-2",
              staticStyle: { "padding-top": "10px" },
            },
            [
              _c("a", { attrs: { href: "/sitemap" } }, [
                _c("b", [_vm._v(_vm._s(_vm.$t("bottom.Sitemap")))]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(0),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "column is-2", staticStyle: { "padding-top": "10px" } },
      [
        _c(
          "a",
          { attrs: { target: "_blank", href: "https://fidget-spinner.net" } },
          [_c("b", [_vm._v("Fidget\n        Spinner")])]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }